import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import ProjectResearchSection from "./projectResearchSection/ProjectResearchSection";
import ProjectToolsSections from "./projectToolsSections/ProjectToolsSections";

import { makeStyles } from "@mui/styles";
import UploadSection from "./uploadSection/UploadSection";
// import DownloadSection from "./downloadSecion/DownloadSection";
import FeedbackSection from "./feedback/FeedbackSection";

const useStyles = makeStyles((theme) => ({
  res: {
    fontSize: "18px",
    color: theme.palette.secondary.main,
    textDecoration: "underline",
    whiteSpace: "normal",
    overflow: "auto",
    cursor: "pointer",
  },
  nameDate: {
    fontSize: "16px",
    marginTop: "4px",
    paddingRight: "4px",
  },
  provider: {
    fontSize: "18px",
  },
  title: {
    fontSize: "16px",
    color: theme.palette.secondary.main,
  },
}));

const ProjectRightComponent = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const activeSidebarSection = useSelector(
    (state) => state.sideBar.activeSidebarSection
  );

  return (
    <Grid
      pt={"2rem"}
      pl={matches ? 4 : 6}
      container
      item
      lg={10}
      md={10}
      sm={10}
      xs={10}
      textAlign="left"
    >
      {activeSidebarSection === "Research" ? (
        <ProjectResearchSection generalClass={classes} />
      ) : activeSidebarSection === "Tools" ? (
        <ProjectToolsSections generalClass={classes} />
      ) : activeSidebarSection === "Folders" ? (
        <UploadSection generalClass={classes} />
      ) : activeSidebarSection === "Feedback Pro" ? (
        <FeedbackSection generalClass={classes} />
      ) : null}
      {/* <DownloadSection generalClass={classes} /> */}
    </Grid>
  );
};

export default ProjectRightComponent;
